.error-filter__container {
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: url("../../../assets/images/svg/arrow-down-for-native-select.svg");

    position: absolute;
    top: 32px;
    right: 5px;
  }

  select {
    appearance: none;
    padding: 0 5px;
  }

  span {
    color: #8f939b;
    font-size: 12px;
  }

  .additional-filters__select {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid #434543;
    background-color: #434543;
    width: 250px;
    height: 38px;

    option {
      color: #9699a2;
    }
  }
}

.reboot-dropdown-wrapper {
  margin-right: 15px;

  .drop-down-list-reboot .drop-down-list-reboot-btn {
    max-height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
}

.install-firmware-btn .is-large-button {
  max-height: 38px;
}