.display-none {
  display: none !important;
}

span.thank_you_email {
  color: #fff;
  cursor: pointer;
}

.thank_you_email-content {
  min-width: 100%;
  border-radius: 24px;
  background: #2a2e2a;
  backdrop-filter: blur(37.5px);
  padding: 70px 10% 40px 10%;
}

.thank_you_email-wrapper {
  flex: 0 1 70%;
}

.thank_you_email-container {
  .btn-upd-dashb {
    margin-bottom: 5%;
  }
}
