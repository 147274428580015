.cancel-button {
    display: inline-block;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;

    width: auto;
    height: 39px;
    padding: 0.65rem 1rem;
    margin: 0;

    border: 1px solid #a3238e;
    border-radius: 60px;

    font-size: 16px;
    font-weight: 500;

    line-height: 0.9;
    opacity: 1;
    color: #fff;

    cursor: pointer;

    &:hover:not(:disabled):not(.active) {
        background-color: #a3238e;
    }

    &:focus {
        border-color: #a3238e;
        background-color: #a3238e;
    }
}

.modal-footer {
    .cancel-button {
        color: #fff;
        background: transparent;
        border: 1px solid #fff;

        &:hover:not(:disabled):not(.active) {
            background: #fff;
            color: #000;
        }
    }
}
