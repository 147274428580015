.add-gateway-preloader-container,
.installedsm-delete-prealoder-container {
  height: 185px;
  display: flex;
  align-items: center;
}

.installed-sm-expander-filters {
  row-gap: 10px;
  column-gap: 50px;
  background-color: #2A2E2A;
}

.version-field {
  min-width: 250px;
}

.firmware-container {
  max-width: 770px;
  margin-bottom: 15px;
}

.row-gap-5 {
  row-gap: 5px;
}

.column-gap-10 {
  column-gap: 10px;
}

.modal-body-gateway {
  padding-top: 25px !important;
}

.name-or-smid-note {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #ffffff;
  background-color: #1C1E1C;
}

.confirmation-field {
  margin: 0 auto;
  padding: 0 50px;
}

.confirmation-field>.customTextField {
  width: 100%;
}

.confirmation-field>.customTextField>label {
  padding-left: 10px;
  font-size: 16px;
}

.confirmation-field>.customTextField>.inputRoot>input {
  border: 1px solid transparent;
  padding-left: 10px;
}

.confirmation-field>.customTextField>.inputRoot>input:focus {
  border: 1px solid #A3238E;
}

.multi-select {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  margin: 15px 15px !important;
  color: #ffffff7f;
  margin-top: 15px;
}

.confirm-deleting-text {
  font-size: 16px !important;
}
