.opened__main {
  display: flex;
  gap: 15px;
  padding: 15px 20px 25px 20px;

  .opened__container {
    max-width: 225px;
    width: 100%;

    .opened-time__container {
      display: flex;
      flex-direction: column;
      margin-bottom: 17px;

      .opened-time__container__range {
        display: flex;
        gap: 18px;
      }

      .time-picker-container {
        position: absolute;
        width: 104px;
      }

      .opened-time__range {
        max-width: 125px;

        .customTextField label[data-shrink="true"] {
          color: #fff !important;
          opacity: 0.5;
        }
      }
    }

    .opened-switch__container .opened-switch-action,
    .opened-days__container .opened-days-active {
      span {
        font-size: 11px;
        margin-top: 5px;
        font-weight: 400;
        color: var(--primary-color);
      }
    }
  }

  .opened-button {
    display: flex;
    width: 40px;
    height: 40px;
    margin-top: 5px;

    img {
      cursor: pointer;
    }
  }
}

.opened__time-picker .time-picker-container {
  position: absolute;
}

.opened__time-picker.customTextField .inputRoot:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.is-disabled-img {
  pointer-events: none;
}

.opened-days-active,
.opened-switch-action {
  margin-bottom: 11px;
}