.invited-users-wrapper {
  .rt-noData.customNoData {
    top: 95px;
    left: calc(50% + 15px);
  }
  .email {
    color: var(--primary-color);
  }
  .react-tabs__tab-list {
    border: none;
  }
}
