.maintenance-container {
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h2 {
        font-weight: 700;
        font-size: 40px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    span {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.5);
    }

    & > span:nth-child(4) {
        margin-bottom: 10px;
    }

    #big-bottom-circle {
        fill: #4a4b52;
    }

    #big-bottom-gear,
    #path264 {
        fill: #c6c6d2;
    }

    #little-bottom-circle {
        fill: #686970;
    }

    #right-bottom-gear {
        fill: #56565e;
    }

    #right-top-gear,
    #Path_3537,
    #Path_3537-2 {
        fill: #474856;
    }

    #left-top-little-gaer {
        fill: #3c3c44;
    }

    #left-top-big-gear {
        fill: #7f7f83;
    }
}
