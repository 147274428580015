.dashboard-info {
    width: 100%;
    height: 100%;
    background-size: contain;
    position: absolute;
    bottom: 0;

    &-container {
        float: right;

        button {
            padding-left: 21px;
            padding-right: 21px;
            color: var(--primary-color);
            font-size: 16px;
            font-weight: 500;
            line-height: 60px;

            background-color: transparent;
            border: none;
            cursor: pointer;

            &:hover {
                color: var(--primary-color);
                text-decoration: underline;
            }
        }
    }
}
